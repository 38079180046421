import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024.000000 1024.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)">


<path d="M3105 7864 c-11 -2 -47 -10 -80 -16 -147 -28 -275 -99 -400 -223 -97
-96 -161 -192 -196 -291 -46 -131 -49 -163 -49 -676 0 -269 2 -488 5 -488 2 0
24 8 47 17 66 26 146 77 195 123 80 77 77 61 83 465 5 339 7 363 27 413 68
168 224 303 383 332 37 7 735 9 2045 8 l1990 -3 55 -24 c125 -56 226 -145 278
-246 47 -94 52 -130 52 -435 0 -389 9 -433 99 -517 45 -43 192 -123 224 -123
16 0 17 24 16 273 0 395 -14 720 -33 789 -18 66 -61 158 -111 240 -45 73 -182
211 -201 204 -8 -3 -13 -2 -10 3 7 10 -48 51 -69 51 -8 0 -15 4 -15 10 0 26
-196 97 -290 105 -80 7 -4017 16 -4045 9z"/>
<path d="M6675 7048 c-91 -24 -155 -111 -155 -211 0 -31 6 -69 12 -84 53 -117
207 -170 309 -104 24 15 53 39 65 52 27 30 54 101 54 138 0 81 -89 188 -175
210 -50 13 -56 13 -110 -1z"/>
<path d="M4815 6858 c-74 -62 -180 -161 -194 -181 -7 -10 -21 -54 -31 -96 -24
-107 -81 -284 -138 -431 -60 -152 -198 -494 -217 -535 -22 -49 -96 -228 -102
-247 -3 -10 -8 -18 -12 -18 -3 0 -23 39 -43 88 -53 124 -127 293 -138 317 -5
11 -55 133 -110 271 -99 250 -149 414 -168 552 -8 57 -17 79 -39 105 -38 43
-213 200 -218 195 -4 -3 -7 -1455 -6 -2606 l1 -563 73 60 c39 33 99 85 132
114 l60 54 5 904 5 904 46 -95 c25 -52 103 -219 173 -370 171 -373 208 -445
226 -445 8 0 24 18 35 41 47 94 129 271 203 434 103 227 204 430 214 430 4 0
8 -393 8 -872 0 -584 4 -885 11 -908 8 -27 39 -59 140 -143 l129 -108 0 503 0
503 26 -33 c30 -38 73 -63 100 -57 10 2 31 6 46 9 20 5 52 38 118 124 116 150
142 184 241 317 45 61 92 121 104 134 l23 24 32 -57 c18 -31 34 -56 37 -56 3
0 50 31 106 68 56 38 123 81 149 96 l48 28 2 355 3 355 160 -1 c202 -2 270
-13 372 -62 145 -69 247 -174 340 -351 22 -42 42 -68 52 -68 16 1 74 44 243
181 130 106 126 100 93 152 -67 102 -160 204 -260 286 -113 93 -197 142 -340
197 -167 65 -175 66 -671 71 l-451 5 -6 -24 c-4 -12 -7 -231 -7 -485 0 -330
-3 -464 -11 -469 -6 -4 -77 -95 -157 -203 -80 -108 -170 -229 -201 -270 -141
-186 -171 -226 -171 -233 0 -5 -4 -8 -10 -8 -6 0 -11 370 -12 1076 l-3 1076
-40 -34z"/>
<path d="M7690 5839 c-187 -18 -396 -123 -595 -300 -16 -14 -64 -57 -105 -94
-201 -179 -291 -225 -590 -300 -375 -93 -578 -211 -857 -497 -85 -87 -113
-123 -118 -149 -3 -19 -5 -140 -3 -269 l3 -235 445 0 c399 0 453 2 525 19 417
97 702 351 857 764 l20 53 -48 62 c-26 34 -51 66 -55 71 -4 4 -40 -12 -81 -37
-98 -61 -153 -85 -262 -111 l-91 -22 -39 -75 c-52 -98 -165 -212 -258 -257
-120 -59 -147 -64 -356 -69 l-193 -5 3 153 3 154 69 46 c148 99 282 155 501
210 328 81 437 140 715 385 153 134 186 159 275 211 87 50 151 71 246 82 131
14 261 -16 355 -84 19 -14 22 -13 51 27 46 64 65 107 58 128 -4 11 -10 20 -14
20 -4 0 -16 9 -28 19 -24 22 -107 62 -178 84 -59 18 -165 25 -255 16z"/>
<path d="M3064 5765 c-12 -8 -67 -85 -123 -172 -56 -87 -156 -237 -222 -333
-66 -96 -125 -182 -130 -190 -8 -13 -12 -11 -26 10 -10 14 -33 40 -51 58 l-33
32 -192 0 c-173 0 -195 -2 -224 -20 -18 -11 -35 -31 -39 -45 -11 -43 3 -98 31
-122 25 -22 35 -23 175 -23 l149 0 30 -46 c17 -25 31 -48 31 -52 0 -5 17 -24
38 -44 48 -47 88 -55 144 -30 46 20 26 -7 464 624 60 87 120 178 133 203 24
46 24 46 5 81 -40 75 -110 105 -160 69z"/>
<path d="M7297 5380 l-76 -71 20 -37 c12 -20 25 -41 29 -47 4 -5 21 -35 37
-65 70 -127 330 -560 350 -584 31 -35 74 -34 113 2 16 15 72 106 124 202 l93
175 127 5 128 5 29 33 c41 46 40 97 -2 138 l-30 29 -191 0 -192 0 -53 -100
c-29 -55 -61 -117 -70 -137 -10 -21 -20 -38 -24 -38 -3 0 -11 12 -18 27 -7 15
-40 75 -73 133 -34 58 -69 119 -78 135 -9 17 -44 75 -76 130 -33 55 -63 108
-67 118 -3 9 -10 17 -15 17 -4 0 -43 -32 -85 -70z"/>
<path d="M2376 4106 c-4 -73 -6 -349 -5 -612 1 -398 4 -487 17 -534 8 -30 15
-68 14 -83 -1 -15 3 -25 7 -22 8 4 21 -28 25 -60 3 -17 30 -70 41 -77 5 -4 10
-15 11 -25 3 -48 5 -53 24 -53 11 0 20 -4 20 -8 0 -15 37 -52 44 -45 3 3 6 -1
6 -10 0 -9 5 -17 10 -17 6 0 10 -6 10 -14 0 -8 15 -24 33 -35 19 -11 46 -32
61 -46 15 -14 39 -28 54 -31 15 -4 51 -20 80 -36 28 -16 52 -27 52 -24 0 3 15
0 33 -5 74 -23 242 -35 462 -33 127 1 821 4 1544 8 722 3 1316 8 1319 11 3 3
-141 6 -319 7 -178 1 29 5 461 8 542 4 794 10 815 17 17 7 57 19 90 28 143 40
297 140 397 259 64 76 135 206 169 311 22 69 23 77 26 663 3 397 1 592 -6 592
-32 0 -182 -92 -237 -145 -94 -91 -94 -93 -94 -529 0 -199 -5 -392 -10 -430
-24 -167 -166 -325 -347 -388 l-68 -23 -1985 0 -1985 0 -65 23 c-134 49 -253
150 -311 264 -56 112 -59 136 -59 565 0 369 -1 396 -19 432 -36 69 -124 142
-248 205 -29 14 -54 26 -56 26 -2 0 -7 -60 -11 -134z m144 -1447 c0 -6 -4 -7
-10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m1223 -296 c-13
-2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m320 0 c-13 -2 -33 -2 -45
0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m160 0 c-13 -2 -33 -2 -45 0 -13 2 -3 4
22 4 25 0 35 -2 23 -4z m580 0 c-46 -2 -120 -2 -165 0 -46 1 -9 3 82 3 91 0
128 -2 83 -3z m280 0 c-24 -2 -62 -2 -85 0 -24 2 -5 4 42 4 47 0 66 -2 43 -4z
m180 0 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m220 0 c-24
-2 -62 -2 -85 0 -24 2 -5 4 42 4 47 0 66 -2 43 -4z m-2283 -13 c0 -5 -10 -10
-22 -10 -19 0 -20 2 -8 10 19 13 30 13 30 0z"/>
<path d="M4027 3453 c-15 -23 -107 -248 -107 -259 0 -8 11 -14 26 -14 19 0 28
7 35 30 10 29 14 30 68 30 54 0 57 -1 68 -30 10 -26 16 -30 40 -26 15 2 29 5
30 5 1 1 -24 61 -56 134 -45 104 -62 133 -78 135 -12 2 -23 0 -26 -5z m42
-110 c16 -56 14 -59 -23 -55 l-34 4 15 44 c8 24 19 44 23 44 5 0 14 -17 19
-37z"/>
<path d="M4313 3453 c-102 -4 -103 -4 -103 -28 0 -21 5 -25 29 -25 35 0 41
-22 41 -141 l0 -79 39 0 c34 0 40 4 50 30 9 28 13 30 65 30 51 0 57 -2 70 -29
11 -21 22 -29 41 -27 14 0 25 6 25 12 0 15 -97 234 -112 251 -6 7 -18 12 -27
11 -9 -1 -62 -4 -118 -5z m52 -118 c-15 -41 -29 -75 -31 -75 -2 0 -4 28 -4 63
0 57 2 63 28 78 15 9 29 14 31 13 2 -2 -9 -37 -24 -79z m105 -44 c0 -9 -8 -11
-27 -6 -38 9 -38 9 -23 57 l13 43 18 -40 c10 -22 19 -46 19 -54z"/>
<path d="M4825 3448 c-31 -18 -44 -40 -45 -74 0 -38 22 -59 83 -80 51 -17 60
-37 26 -55 -18 -9 -27 -7 -50 10 -29 22 -54 20 -63 -6 -9 -21 9 -40 50 -53 79
-26 151 20 140 90 -6 38 -39 69 -73 70 -35 0 -53 11 -53 32 0 18 6 20 58 16
51 -3 57 -1 60 18 5 34 -90 57 -133 32z"/>
<path d="M5656 3452 c-6 -10 18 -83 62 -187 27 -62 34 -70 60 -73 26 -3 30 1
46 45 10 26 28 73 41 103 48 113 48 110 17 110 -25 0 -31 -7 -52 -57 -13 -32
-30 -74 -38 -93 l-15 -35 -36 95 c-29 74 -42 96 -58 98 -12 2 -24 -1 -27 -6z"/>
<path d="M6023 3452 c-23 -3 -23 -6 -23 -138 l0 -135 28 3 27 3 3 129 c1 71
-1 132 -5 136 -4 3 -18 4 -30 2z"/>
<path d="M6250 3449 c-116 -46 -119 -216 -4 -259 38 -15 102 -4 133 21 23 19
23 20 5 34 -22 17 -40 19 -49 5 -12 -19 -71 -11 -93 13 -65 69 20 175 96 121
21 -15 25 -15 43 2 27 24 24 31 -21 54 -43 22 -71 24 -110 9z"/>
<path d="M3620 3320 l0 -130 76 0 c75 0 77 1 115 39 35 35 39 44 39 91 0 47
-4 56 -39 91 -38 38 -40 39 -115 39 l-76 0 0 -130z m151 54 c23 -21 25 -81 3
-112 -22 -32 -89 -32 -98 1 -8 29 -7 86 1 116 5 20 10 22 41 18 19 -4 43 -14
53 -23z"/>
<path d="M5078 3320 l3 -130 28 0 c15 0 56 -3 90 -6 l61 -7 0 32 0 31 -65 0
c-63 0 -65 1 -65 25 0 24 3 25 55 25 54 0 55 0 55 29 0 29 -2 30 -52 33 -46 3
-53 6 -56 26 -3 21 0 22 62 22 64 0 66 1 66 25 0 25 -1 25 -92 25 l-93 0 3
-130z"/>
<path d="M5372 3318 l3 -133 25 0 c23 0 25 4 28 48 2 26 5 47 7 47 25 0 55
-25 64 -53 9 -26 19 -36 39 -40 40 -8 44 0 22 43 -25 49 -25 54 1 90 19 25 20
33 10 61 -21 61 -36 69 -123 69 l-79 0 3 -132z m138 63 c16 -31 2 -49 -41 -53
l-39 -4 0 38 c0 37 1 38 35 38 24 0 37 -6 45 -19z"/>
<path d="M6502 3319 l3 -131 85 0 c84 0 85 0 88 26 3 25 2 26 -56 26 -41 0
-61 4 -65 13 -9 25 8 36 58 39 47 3 50 5 53 32 l4 29 -56 -6 -56 -6 0 30 c0
29 0 29 60 29 57 0 60 1 60 25 0 25 -1 25 -90 25 l-91 0 3 -131z"/>
<path d="M6418 2353 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M6638 2353 c45 -2 119 -2 165 0 45 1 8 3 -83 3 -91 0 -128 -2 -82 -3z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
